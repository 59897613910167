import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { FormikHelpers } from "formik";
import EmailForm from "../forms/SignUp/EmailForm";
import firebase from "firebase/compat/app";
import { useAuth } from "../contexts/AuthContext";
import * as Sentry from "@sentry/browser";
import { Helmet } from "react-helmet";
interface FormValues {
  email: string;
}

interface Props {}

const ForgotPassword: React.FC<Props> = ({ ...props }) => {
  const history = useNavigate();
  const { resetPassword } = useAuth();
  const [alert, setAlert] = useState<{ status: AlertColor; message: string }>({
    status: "success",
    message: "",
  });
  const [open, setOpen] = useState(false);

  const getTitle = () => {
    let _title = document.title;
    _title = "Forgot Password | " + _title;
    return _title;
  };

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    formikHelpers.setSubmitting(true);
    const email = values.email;
    await resetPassword(email)
      .then(() => {
        setAlert({
          status: "success",
          message: `Email sent to ${email}`,
        });
        setOpen(true);
      })
      .catch((e: any) => {
        const err = e as firebase.FirebaseError;
        switch (err.code) {
          case "auth/invalid-email":
            setAlert({
              status: "error",
              message: "Invalid email address",
            });
            break;
          case "auth/user-not-found":
            setAlert({
              status: "error",
              message: "No user found with this email address",
            });
            break;
          default:
            setAlert({
              status: "error",
              message: "Some error occurred. Contact support.",
            });
            Sentry.captureException(err);
        }
        setOpen(true);
        formikHelpers.setSubmitting(false);
      });
  };

  const handleCancelled = () => {
    history("/login");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://storage.googleapis.com/hushup-assets-public/bg-login.webp)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
            flexDirection="column"
            minHeight="100vh"
            style={{ backgroundColor: "#fff" }}
          >
            <Grid
              container
              item
              justifyContent={"flex-start"}
              alignItems={"center"}
              spacing={2}
              xs={10}
            >
              <Grid item>
                <Typography component="h1" variant="h3">
                  Forgot Password
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={"textSecondary"}>
                  Enter the email address associated with your account. We'll
                  send a link to reset password on that email address.
                </Typography>
              </Grid>
            </Grid>
            <EmailForm onSubmit={handleSubmit} />
            <Button sx={{ textTransform: "none" }} onClick={handleCancelled}>
              Back to Login
            </Button>
          </Box>
        </Grid>
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={alert.status}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default ForgotPassword;
