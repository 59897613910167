import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import ProfileForm from "../../forms/ProfileForm";
import {
  checkUsernameAvailability,
  getMe,
  patchMe,
  postMe,
} from "../../utils/api";
import { useApp } from "../../contexts/AppContext";
import { ApiResponse } from "../../codelibrary/Models/ApiResponse";
import { UserPublic } from "../../codelibrary/Models/UserPublic";
import * as Sentry from "@sentry/browser";

interface FormValues {
  username: string;
  fullName: string;
  shortBio: string;
}

interface Props {}

const UserProfileCreate: React.FC<Props> = ({ ...props }) => {
  const history = useNavigate();
  const { token } = useAuth();
  const { appUser, updateAppUser } = useApp();
  const [alert, setAlert] = useState<{
    severity: "error" | "success";
    value?: string;
  }>();
  const [profile, setProfile] = useState<UserPublic | any>({
    ...new UserPublic(),
  });

  useEffect(() => {
    getMe(token)
      .then((res) => {
        setProfile(res.data);
        updateAppUser(res.data);
        history("..");
      })
      .catch((err: ApiResponse) => {
        Sentry.captureException(err);
      });
  }, []);

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const _username = values.username.toLowerCase();
    let usernameAvialable = false;
    await checkUsernameAvailability(_username)
      .then((res: ApiResponse) => {
        if (res.status === 200) {
          formikHelpers.setErrors({
            username: "Username not available",
          });
          formikHelpers.setSubmitting(false);
        }
      })
      .catch((err: ApiResponse) => {
        if (err.status === 404) {
          usernameAvialable = true;
        } else {
          formikHelpers.setErrors({
            username: "Some error occurred. Try later.",
          });
          formikHelpers.setSubmitting(false);
        }
      });

    if (!usernameAvialable) {
      return;
    }

    const _fullName = values.fullName;
    const _shortBio = values.shortBio;
    await postMe(_username, _fullName, _shortBio, token)
      .then(async (data) => {
        await updateAppUser(data);
        history("..");
      })
      .catch((err: ApiResponse) => {
        formikHelpers.setErrors({
          shortBio: "Some error occurred. Try later.",
        });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} component={Paper} elevation={6} square>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          minHeight="100vh"
          style={{ backgroundColor: "#fff" }}
        >
          <Typography component="h1" variant="h3" paddingBottom={4}>
            Create Profile
          </Typography>
          <ProfileForm
            initialValues={{
              username: "",
              fullName: "",
              shortBio: "",
            }}
            onSubmit={handleSubmit}
            alert={alert!}
            mode={0}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserProfileCreate;
