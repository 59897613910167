import rose from './rose.json';
import promise from './promise.json';
import teddy from './teddy.json';
import hug from './hug.json';
import propose from './propose.json';
import kiss from './kiss.json';
import chocolate from './chocolate.json';
import valentine from './valentine.json';

export const getAnimationByTopic = (topic: string) => {
    let animation
    switch (topic) {
        case 'rose':
            animation = rose
            break;
        case 'promise':
            animation = promise
            break;
        case 'teddy':
            animation = teddy
            break;
        case 'hug':
            animation = hug
            break;
        case 'propose':
            animation = propose
            break;
        case 'kiss':
            animation = kiss
            break;
        case 'chocolate':
            animation = chocolate
            break;
        case 'valentine':
            animation = valentine
        break;
        
        default:
            break;
    }
    return animation
}