import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Card,
  Divider,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { UserPublic } from "../codelibrary/Models/UserPublic";
import {
  getUserInfo,
  getUserPublicProfile,
  pingifyMessage,
  sendSticker,
} from "../utils/api";
import { GetAppOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Lottie from "react-lottie-player";
import Footer from "../footer/Footer";
import { FormikHelpers } from "formik";
import { stickerTopicList } from "../utils/constants";
import StickerForm from "../forms/StickerForm";
import InArticle from "../adsense/InArticle";
import { Box } from "@mui/system";
import { getAnimationByTopic } from "../utils/lottie/helper";

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    flexGrow: "inherit",
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  textWhite: {
    color: theme.palette.primary.contrastText,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    backgroundColor: theme.palette.secondary.main,
  },
  profileBox: {
    marginTop: "1em",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  bodyMargin: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
  fillerText: {
    color: theme.palette.text.disabled,
  },
  ripple: {
    backgroundPosition: "center",
    transition: "background 0.8s",
    "&:hover": {
      background:
        "#f9f9f9 radial-gradient(circle, transparent 1%, #f6f6f6 1%) center/15000%",
    },
    "&:active": {
      backgroundColor: "#f9f9f9",
      backgroundSize: "100%",
      transition: "background 0s",
    },
  },
  lottieCard: {
    border: "none",
    borderRadius: "16px",
    padding: "12px 18px",
    fontSize: "16px",
    textTransform: "uppercase",
    cursor: "pointer",
    color: "white",
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px #999",
    outline: "none",
  },
}));

interface FormValues {
  topic: string;
  textBody: string;
  textTo: string;
  textFrom: string;
}

interface Props {}

const SendStickerPage: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles();

  const [profile, setProfile] = useState<UserPublic>();
  const [fetchingProfile, setFetchingProfile] = useState(true);
  const [response, setResponse] = useState<{
    status: AlertColor;
    message: string;
  }>({ status: "success", message: "" });
  const [userInfo, setUserInfo] = useState("");
  const [messages, setMessages] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const { id } = useParams();

  const getTitle = () => {
    if (!profile) {
      return;
    }
    let _t = document.title;
    let _title = "";
    if (profile!.fullName) {
      _title += profile!.fullName;
    }
    if (profile!.shortBio) {
      _title += " - " + profile!.shortBio;
    }
    if (_title !== "") {
      _title += " | " + document.title;
      _t = _title;
    }
    return _t;
  };

  const getDescription = () => {
    if (!profile) {
      return;
    }
    return "Send anonymous sticker to " + profile.fullName;
  };

  const getUrl = () => {
    let url = "https://hushup.app/";
    if (!profile) {
      return url;
    }
    return url + profile.username;
  };

  useEffect(() => {
    getUserPublicProfile(id!)
      .then((data) => {
        setProfile(data.data);
        setFetchingProfile(false);
        let t_messages: any = [];
        if (data.data.messages) {
          Object.values(data.data?.messages).forEach(
            (value: any, index: any) => {
              t_messages.push(value);
            }
          );
        }
        setMessages(t_messages);
      })
      .catch((error) => {
        setIsError(true);
        setFetchingProfile(false);
        setResponse({
          status: "error",
          message: error.msg,
        });
      });
    const x = getUserInfo().then((r) => {
      setUserInfo((prevState) =>
        Buffer.from(JSON.stringify({ ip: r.data.ip }), "utf8").toString(
          "base64"
        )
      );
    });
  }, []);

  const handleSubmit = (
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const _textBody = formValues.textBody;
    const _textTo = formValues.textTo;
    const _textFrom = formValues.textFrom;
    const _topic = formValues.topic;

    sendSticker(id!, userInfo, {
      textBody: _textBody,
      textTo: _textTo,
      textFrom: _textFrom,
      topic: _topic,
      id: profile?.id,
    })
      .then((data) => {
        if (data!.status === 200) {
          setResponse({
            status: "success",
            message: data!.data.msg,
          });
          setOpen(true);
          formikHelpers.resetForm();
          pingifyMessage(data!.data.data);
          return;
        }
      })
      .catch((err) => {
        setResponse({
          status: "error",
          message: err.msg,
        });
        setOpen(true);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta property="og:url" content={getUrl()} />

        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta property="twitter:url" content={getUrl()} />
      </Helmet>
      <Grid
        container
        justifyContent="center"
        className={clsx(
          classes.bodyMargin,
          messages.length > 0 ? classes.profileBox : ""
        )}
      >
        <Grid
          item
          xl={4}
          lg={6}
          md={8}
          xs={10}
          sm={8}
          direction="column"
          alignItems="center"
        >
          <Card className={classes.root}>
            <CardContent>
              {!fetchingProfile && profile !== undefined && (
                <Grid container justifyContent={"center"}>
                  <Grid container item xs={8} justifyItems={"center"}>
                    <Grid container item xs={12} justifyContent={"center"}>
                      <Avatar
                        alt={profile.fullName}
                        src="/broken-image.jpg"
                        className={classes.avatar}
                        sx={{
                          width: 84,
                          height: 84,
                          fontSize: "x-large",
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      direction={"column"}
                      xs={12}
                      alignItems={"center"}
                    >
                      <Typography variant="h5" display="inline">
                        {profile.fullName}
                      </Typography>
                      <Typography variant="subtitle1" display="inline">
                        @{profile.username}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        paddingTop={2}
                      >
                        {profile.shortBio}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid
                      container
                      item
                      direction={"column"}
                      xs={12}
                      alignItems={"center"}
                    >
                      <SendSticker onSubmit={handleSubmit} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fetchingProfile && profile === undefined && (
                <Alert severity={"error"}>{response.message}</Alert>
              )}
            </CardContent>
            <CardActions>
              {profile && profile!.appOnly && (
                <Grid container justifyContent={"center"}>
                  <Grid>
                    <Button variant={"contained"} className={classes.textWhite}>
                      <GetAppOutlined />
                      get the app
                    </Button>
                  </Grid>
                </Grid>
              )}
            </CardActions>
          </Card>
          {((profile && messages.length === 0) || isError) && (
            <Grid
              container
              item
              xs={12}
              justifyContent={"center"}
              sx={{ marginTop: "32px" }}
            >
              <Typography
                variant={"inherit"}
                className={classes.fillerText}
                gutterBottom
                align="justify"
              >
                HushUP is a free platform for sharing views, ideas, thoughts
                anonymously. HushUP allows users to create unique link to their
                profile for sharing and gathering feedbacks, reviews, thoughts
                from their networks. This link can be shared over social
                networks like WhatsApp, LinkedIn, Facebook, Instagram etc. Users
                can receive messages from people on HushUP. These messages will
                be completely anonymous. A salient feature of HushUP is that,
                users can reply to messages too. Rest assured, the sender’s
                identity will always be anonymous.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Footer quickLinks={false} />
      </Grid>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={response.status}
          sx={{ width: "100%" }}
        >
          {response.message}
        </Alert>
      </Snackbar>
    </>
  );
};

interface SendStickerProps {
  onSubmit: (
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
}

const SendSticker: React.FC<SendStickerProps> = ({ onSubmit, ...props }) => {
  const classes = useStyles();
  const [selectedSticker, setSelectedSticker] = useState<any>();

  return (
    <>
      {!selectedSticker && (
        <Typography align="center" variant="h5">
          Choose Sticker
        </Typography>
      )}
      {!selectedSticker && (
        <Grid container justifyContent="space-evenly">
          {stickerTopicList.map((sticker: any) => {
            return (
              <Grid
                container
                item
                justifyContent="center"
                xs={4}
                component={Card}
                marginX={2}
                marginBottom={2}
                className={`${classes.ripple} ${classes.lottieCard}`}
                sx={{ backgroundColor: "#f8f8f8" }}
                onClick={() => {
                  setSelectedSticker(sticker);
                }}
              >
                <Lottie
                  loop={false}
                  animationData={getAnimationByTopic(sticker.topic)}
                  play
                />
                <Typography
                  variant="caption"
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {sticker.topic}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
      {!!selectedSticker && (
        <Grid
          container
          item
          xs={12}
          direction="column"
          alignItems="center"
          mt={2}
        >
          <Grid item xs={2} onClick={() => {}}>
            <Card
              sx={{
                backgroundColor: "#f8f8f8",
                padding: "2px",
              }}
            >
              <Lottie
                loop={false}
                animationData={getAnimationByTopic(selectedSticker.topic)}
                play
                style={{
                  maxWidth: 180,
                  maxHeight: 180,
                }}
              />
            </Card>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid container item xs={12} justifyContent={"center"} mt={2}>
              <StickerForm
                sticker={selectedSticker}
                handleSubmit={onSubmit}
                comp={<InArticle />}
              />
            </Grid>
            <Divider variant="fullWidth" />
            <Grid container item xs={12} justifyContent={"center"}>
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setSelectedSticker(undefined);
                }}
              >
                More Stickers
              </Button>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default SendStickerPage;
