import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import ProfileForm from "../../forms/ProfileForm";
import { patchMe } from "../../utils/api";
import { useApp } from "../../contexts/AppContext";
import { ApiResponse } from "../../codelibrary/Models/ApiResponse";
import * as Sentry from "@sentry/browser";

interface FormValues {
  username: string;
  fullName: string;
  shortBio: string;
}

interface Props {}

const UserProfile: React.FC<Props> = ({ ...props }) => {
  const history = useNavigate();
  const { token } = useAuth();
  const { appUser, updateAppUser } = useApp();
  const [alert, setAlert] = useState<{
    severity: "error" | "success";
    value?: string;
  }>();

  useEffect(() => {
    if (!appUser) {
      history("..");
    }
  }, [appUser]);

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    await patchMe(
      {
        fullName: values.fullName,
        shortBio: values.shortBio,
      },
      appUser.id,
      token
    )
      .then((data) => {
        updateAppUser(data);
        history("/me");
        setAlert(undefined);
      })
      .catch((err: ApiResponse) => {
        setAlert({
          severity: "error",
          value: "Some error occurred. Try later.",
        });
        Sentry.captureException(err);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} component={Paper} elevation={0} square>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          flexDirection="column"
          minHeight="100vh"
          style={{ backgroundColor: "#fff" }}
        >
          <Typography component="h1" variant="h3" paddingBottom={4}>
            Your Profile
          </Typography>
          <ProfileForm
            initialValues={{
              username: appUser?.username,
              fullName: appUser?.fullName,
              shortBio: appUser?.shortBio,
            }}
            onSubmit={handleSubmit}
            alert={alert!}
          />
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              history("..");
            }}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserProfile;
