import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import FormTextField from "../FormTextField";
import FormPasswordField from "../FormPasswordField";
import { NavigateNextOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

interface FormValues {
  username: string;
  email: string;
  password: string;
}

interface Props {
  initialValues: FormValues;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
}

const SignUpForm: React.FC<Props> = ({ initialValues, onSubmit, ...props }) => {
  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .min(3, "Minimum 3 characters")
      .max(15, "Maximum 15 characters")
      .matches(/^[a-z0-9-_]+$/, "Only letter, numbers, - & _ allowed.")
      .matches(/^[a-z]+.*/, "Start with a letter.")
      .lowercase()
      .required("Required"),
    email: yup.string().email("Enter valid email").required("Required"),
    password: yup.string().min(6, "Minimum 6 characters").required("Required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form noValidate autoComplete="off">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{ backgroundColor: "#fff" }}
          >
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              xs={10}
            >
              <Typography component="h1" variant="h3" paddingBottom={4}>
                Create Account
              </Typography>
              <Grid item xs={12}>
                <Field
                  name="username"
                  label="Username"
                  size="small"
                  inputProps={{
                    maxLength: 15,
                    style: { textTransform: "lowercase" },
                  }}
                  component={FormTextField}
                />
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Field
                  name="email"
                  label="Email"
                  size="small"
                  type="email"
                  component={FormTextField}
                />
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Field
                  name="password"
                  label="Password"
                  size="small"
                  type="password"
                  component={FormPasswordField}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                marginTop={4}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color={"primary"}
                  loading={formikProps.isSubmitting}
                  endIcon={<NavigateNextOutlined />}
                  loadingPosition="end"
                >
                  Submit
                </LoadingButton>
              </Grid>
              <Grid container item justifyContent={"center"}>
                <Typography>
                  Already have an account? <Link href="/login">Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
