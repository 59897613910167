import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SignupLinkComponent from "./SignupLinkComponent";

interface Props {
  username: string;
}

const SignupEngagementComponent: React.FC<Props> = ({ username, ...props }) => {
  return (
    <Box p={2}>
      <Grid container>
        <Grid container item xs={12} justifyContent={"center"}>
          <Typography>{`Get you own link like`}&nbsp;</Typography>
          <Typography
            color={"secondary"}
          >{`hushup.app/${username}`}</Typography>
        </Grid>
        <Grid container item xs={12} justifyContent={"center"}>
          <SignupLinkComponent text="Get Link" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignupEngagementComponent;
