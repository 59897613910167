export class ApiResponse {
  private _data!: any;
  private _error!: string;
  private _status!: number;
  private _msg!: string;

  constructor() {
    this.data = "";
    this.error = "";
    this.status = 0;
    this.msg = "";
  }

  get data(): any {
    return this._data;
  }

  set data(value: any) {
    this._data = value;
  }

  get error(): string {
    return this._error;
  }

  set error(value: string) {
    this._error = value;
  }

  get status(): number {
    return this._status;
  }

  set status(value: number) {
    this._status = value;
  }

  get msg(): string {
    return this._msg;
  }

  set msg(value: string) {
    this._msg = value;
  }
}
