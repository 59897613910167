import Link from "@mui/material/Link";
import { isAndroid } from "react-device-detect";
import { PLAY_STORE_LINK } from "../../codelibrary/utility/constants";

interface Props {
  text?: string;
}

const SignupLinkComponent: React.FC<Props> = ({ text, ...props }) => {
  return (
    <>
      {isAndroid && process.env.REACT_APP_WEB_ONBOARDING_ONLY !== "web" && (
        <Link href={`${PLAY_STORE_LINK}`}>{text || `Create Your Account`}</Link>
      )}
      {!isAndroid && process.env.REACT_APP_SIGNUP_AGENT === "web" && (
        <Link href="/signup">{text || `Create Your Account`}</Link>
      )}
    </>
  );
};

export default SignupLinkComponent;
