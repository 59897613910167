import React, { useState } from "react";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormTextField from "../FormTextField";
import { NavigateNextOutlined } from "@mui/icons-material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

interface FormValues {
  username: string;
}

interface Props {
  isUpdateForm?: boolean;
  initialValues: any;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
  onCancel?: () => void;
}

const UsernameForm: React.FC<Props> = ({
  isUpdateForm,
  initialValues,
  onSubmit,
  onCancel,
  ...props
}) => {
  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .min(3, "Minimum 3 characters")
      .max(15, "Maximum 15 characters")
      .matches(/^[a-z0-9-_]+$/, "Only letter, numbers, - & _ allowed.")
      .matches(/^[a-z]+.*/, "Start with a letter.")
      .lowercase()
      .required("Required"),
  });

  return (
    <Grid
      container
      item
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      xs={10}
    >
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form noValidate autoComplete="off">
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  paddingBottom={2}
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {`hushup.app/${values.username.toLowerCase()}`}
                </Typography>
              </Grid>
              <Grid
                container
                item
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Field
                    name="username"
                    label="Username"
                    size="small"
                    inputProps={{
                      maxLength: 15,
                      style: { textTransform: "lowercase" },
                    }}
                    component={FormTextField}
                  />
                </Grid>
                <Grid item xs={12} alignItems={"center"}>
                  <Typography
                    style={{ fontSize: "smaller" }}
                    align="right"
                    variant={"subtitle2"}
                  >
                    {values.username?.length}/15
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction={"column"}
                  alignItems="center"
                  justifyContent="center"
                  marginTop={4}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color={"primary"}
                    loading={isSubmitting}
                    disabled={values.username === initialValues.username}
                    endIcon={
                      isUpdateForm ? (
                        <SaveOutlinedIcon />
                      ) : (
                        <NavigateNextOutlined />
                      )
                    }
                    loadingPosition="end"
                  >
                    {isUpdateForm ? "Save" : "Next"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default UsernameForm;
