import React from "react";
import {
  linearProgressClasses,
  LinearProgressProps,
  styled,
  LinearProgress as LP,
} from "@mui/material";

const BorderLinearProgress = styled(LP)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 400],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? "600" : "800"],
  },
}));

interface Props {}

const LinearProgress: React.FC<LinearProgressProps & Props> = ({
  ...props
}) => {
  return <BorderLinearProgress {...props} />;
};

export default LinearProgress;
