import React, { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { patchUsername } from "../../utils/api";
import { useApp } from "../../contexts/AppContext";
import { ApiResponse } from "../../codelibrary/Models/ApiResponse";
import { UserPublic } from "../../codelibrary/Models/UserPublic";
import UsernameForm from "../../forms/SignUp/UsernameForm";

interface FormValues {
  username: string;
}

interface Props {}

const Username: React.FC<Props> = ({ ...props }) => {
  const history = useNavigate();
  const { token } = useAuth();
  const { appUser, updateAppUser } = useApp();

  useEffect(() => {
    if (!appUser) {
      history("..");
    }
  }, [appUser]);

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const _username = values.username.toLowerCase();
    await patchUsername(_username, appUser?.id!, token)
      .then((data) => {
        updateAppUser(data);
        history("..");
      })
      .catch((err: ApiResponse) => {
        formikHelpers.setErrors({
          username: err.msg,
        });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const handleCancelled = () => {
    history("..");
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={12} component={Paper} elevation={0} square>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          flexDirection="column"
          minHeight="100vh"
          style={{ backgroundColor: "#fff" }}
        >
          <Typography component="h1" variant="h3" paddingBottom={4}>
            Username
          </Typography>
          <UsernameForm
            isUpdateForm={true}
            initialValues={{
              username: appUser?.username,
            }}
            onSubmit={handleSubmit}
            onCancel={handleCancelled}
          />
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => {
              history("..");
            }}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Username;
