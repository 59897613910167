import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { isAndroid } from "react-device-detect";
import { PLAY_STORE_LINK } from "../../codelibrary/utility/constants";

interface Props {
  text?: string;
}

const SignupButtonComponent: React.FC<Props> = ({ text, ...props }) => {
  return (
    <>
      {isAndroid && process.env.REACT_APP_WEB_ONBOARDING_ONLY !== "web" && (
        <Button
          variant={"contained"}
          color={"primary"}
          style={{ margin: "8px" }}
          href={`${PLAY_STORE_LINK}`}
          component={Link}
        >
          {text || `Create Your Account`}
        </Button>
      )}
      {!isAndroid && process.env.REACT_APP_SIGNUP_AGENT === "web" && (
        <Button
          variant={"contained"}
          color={"primary"}
          style={{ margin: "8px" }}
          href="/signup"
        >
          {text || `Create Your Account`}
        </Button>
      )}
    </>
  );
};

export default SignupButtonComponent;
