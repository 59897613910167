import React from "react";
import { Box, Grid, Link as MLink } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import SignupLinkComponent from "../utils/ui/SignupLinkComponent";

const useStyles = makeStyles((theme: any) => ({
  footer: {
    color: theme.palette.text.primary,
    "& a": {
      color: "#606060",
    },
  },
  copyRight: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  quickLinks?: boolean;
}

const Footer: React.FC<Props> = ({ quickLinks }) => {
  const classes = useStyles();
  const history = useNavigate();

  return (
    <Grid
      container
      xl={4}
      lg={6}
      md={6}
      xs={10}
      sm={8}
      className={classes.footer}
    >
      {quickLinks!! && (
        <Grid
          container
          style={{ padding: "16px" }}
          justifyContent={"space-between"}
        >
          <Grid item xs={12}>
            <MLink
              variant="subtitle1"
              component="a"
              href="about"
              style={{ textDecoration: "underline" }}
            >
              About
            </MLink>
          </Grid>
          <Grid item xs={12}>
            <MLink
              variant="subtitle1"
              component="a"
              href="privacy-policy"
              style={{ textDecoration: "underline" }}
            >
              Privacy Policy
            </MLink>
          </Grid>
          <Grid item xs={12}>
            <MLink
              variant="subtitle1"
              component="a"
              href="faq"
              style={{ textDecoration: "underline" }}
            >
              FAQs
            </MLink>
          </Grid>
          <Grid item xs={12}>
            <MLink
              variant="subtitle1"
              component="a"
              href="contact"
              style={{ textDecoration: "underline" }}
            >
              Contact
            </MLink>
          </Grid>
          <Grid item xs={12}>
            <MLink
              variant="subtitle1"
              component="a"
              href="terms-of-service"
              style={{ textDecoration: "underline" }}
            >
              Terms of Service
            </MLink>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} className={classes.copyRight}>
        <Box p={2}>
          <Grid container>
            <Grid container item xs={12} justifyContent={"center"}>
              <SignupLinkComponent />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
