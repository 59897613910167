import React, { useEffect } from "react";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import { PLAY_STORE_LINK } from "../codelibrary/utility/constants";

interface Props {}

const AppLogin: React.FC<Props> = ({ ...props }) => {
  const handleDownloadClicked = () => {
    const win = window.open(PLAY_STORE_LINK, "_blank");
    win?.focus();
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://storage.googleapis.com/hushup-assets-public/bg-login.webp)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          flexDirection="column"
          minHeight="100vh"
          style={{ backgroundColor: "#fff" }}
        >
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={2}
            xs={10}
          >
            <Grid item>
              <Typography component="h1" variant="h3" color="textError">
                Whoops!
              </Typography>
            </Grid>
            <Grid item>
              <Typography color={"textSecondary"}>
                As you are logging in after a very long time, you need to login
                from the app.
              </Typography>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleDownloadClicked}
              >
                Download App
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AppLogin;
