import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Box, Grid, Paper, Typography } from "@mui/material";
import LoginForm from "../forms/LoginForm";
import { FormikHelpers } from "formik";
import firebase from "firebase/compat/app";
import * as Sentry from "@sentry/browser";
import { Helmet } from "react-helmet";

interface FormValues {
  email: string;
  password: string;
}

interface Props {}

const Login: React.FC<Props> = () => {
  const getTitle = () => {
    let _title = document.title;
    _title = "Login | " + _title;
    return _title;
  };

  const { login, updateUser } = useAuth();

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    await login(values.email, values.password)
      .then(async (userCredential: firebase.auth.UserCredential) => {
        await updateUser(userCredential.user);
      })
      .catch((e: any) => {
        const err = e as firebase.FirebaseError;
        switch (err.code) {
          case "auth/user-not-found":
            formikHelpers.setErrors({
              email: "No account with this email",
            });
            break;
          case "auth/wrong-password":
            formikHelpers.setErrors({
              password: "Wrong password",
            });
            break;
          case "auth/network-request-failed":
            formikHelpers.setErrors({
              password: "Network error. Please try later.",
            });
            break;
          default:
            formikHelpers.setErrors({
              email: "Some error occurred. Please try later.",
            });
            Sentry.captureException(err);
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://storage.googleapis.com/hushup-assets-public/bg-login.webp)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            minHeight="100vh"
            style={{ backgroundColor: "#fff" }}
          >
            <Typography component="h1" variant="h3" paddingBottom={4}>
              Sign in
            </Typography>
            <LoginForm onSubmit={handleSubmit} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
