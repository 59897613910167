import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyCYPnXirwIg0B4A4WTk6ItB9VH2lF1hB3E",
  authDomain: "hushup-5a7d9.firebaseapp.com",
  databaseURL: "https://hushup-5a7d9.firebaseio.com",
  projectId: "hushup-5a7d9",
  storageBucket: "hushup-5a7d9.appspot.com",
  messagingSenderId: "107677125523",
  appId: "1:107677125523:web:47f07f92925c6a320cf666",
});

export const auth = app.auth();
if (process.env.REACT_APP_AUTH_SERVER === "emulator") {
  auth.useEmulator(`http://localhost:9099`);
}
export default app;
