import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import { UserPublic } from "../../codelibrary/Models/UserPublic";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  logoutButton: {
    color: "#ff0000",
  },
}));

interface Props {
  profile?: UserPublic;
  togglePrivacyClicked: () => void;
  onLogoutClicked: () => void;
}

const Settings: React.FC<Props> = ({
  profile,
  togglePrivacyClicked,
  onLogoutClicked,
  ...props
}) => {
  const classes = useStyles();
  const history = useNavigate();

  return (
    <Grid container direction={"column"} justifyContent={"space-between"}>
      <Grid item xs={12}>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          subheader={
            <ListSubheader
              sx={{ backgroundColor: "#f8f8f8", fontWeight: "bold" }}
            >
              Settings
            </ListSubheader>
          }
        >
          <ListItem onClick={() => history("../profile")}>
            <ListItemIcon>
              <AccountBoxOutlinedIcon />
            </ListItemIcon>
            <ListItemText id="list-label-edit-profile" primary="Edit Profile" />
          </ListItem>
          <ListItem onClick={() => history("../username")}>
            <ListItemIcon>
              <LinkOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              id="list-label-edit-username"
              primary="Edit Username"
            />
          </ListItem>
          <ListItem
            onClick={() => {
              togglePrivacyClicked();
            }}
          >
            <ListItemIcon>
              <LockOutlinedIcon />
            </ListItemIcon>
            <Box textAlign="right" style={{ paddingRight: 5 }}>
              Privacy
            </Box>
            <ListItemText
              secondaryTypographyProps={{
                align: "right",
                fontWeight: "bold",
              }}
              secondary={profile?.appOnly ? "App Only" : "Public"}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem
            className={classes.logoutButton}
            onClick={() => {
              onLogoutClicked();
            }}
          >
            <ListItemIcon>
              <LogoutOutlinedIcon className={classes.logoutButton} />
            </ListItemIcon>
            <ListItemText id="list-label-logout" primary="Logout" />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default Settings;
