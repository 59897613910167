import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "../footer/Footer";

interface Props {}

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    flexGrow: "inherit",
  },
  infoHeader: {
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: theme.palette.secondary.main,
    zIndex: -1,
    "& h3": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  infoContent: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  footer: {
    color: theme.palette.text.primary,
    "& a": {
      color: "#606060",
    },
  },
  copyRight: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  bodyMargin: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
}));

const PrivacyPolicy: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems={"center"}
      className={classes.bodyMargin}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.infoHeader}
      >
        <Typography variant="h3" align="center" className={classes.infoHeader}>
          Privacy Policy
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          xl={4}
          lg={6}
          md={8}
          xs={10}
          sm={8}
          direction="column"
          alignItems="center"
          paddingTop={1}
        >
          <Card>
            <CardContent>
              <Grid
                container
                justifyContent="center"
                className={classes.infoContent}
              >
                <Grid item xs={12} direction="column" alignItems="center">
                  <div>
                    <Typography variant="body2" style={{ fontStyle: "italic" }}>
                      Effective date: December 13, 2021
                    </Typography>

                    <p>
                      HushUP ("us", "we", or "our") operates the
                      https://www.hushup.app website and the HushUP mobile
                      application (the "Service").
                    </p>

                    <p>
                      This page informs you of our policies regarding the
                      collection, use, and disclosure of personal data when you
                      use our Service and the choices you have associated with
                      that data.
                    </p>

                    <p>
                      We use your data to provide and improve the Service. By
                      using the Service, you agree to the collection and use of
                      information in accordance with this policy.
                    </p>

                    <h2>Anonymity</h2>

                    <p>
                      HushUP is a platform that derives its strength by
                      providing anonymity to its users for communication. Hence,
                      we will always maintain this motto and will never
                      disclose, link, share you identity for the messages you
                      send to your person or persons of interest using our
                      service
                    </p>

                    <h2>Information Collection And Use</h2>

                    <p>
                      We collect several different types of information for
                      various purposes to provide and improve our Service to
                      you.
                    </p>

                    <h3>Types of Data Collected</h3>

                    <h4>Personal Data</h4>

                    <p>
                      While using our Service, we may ask you to provide us with
                      certain personally identifiable information that can be
                      used to contact or identify you ("Personal Data").
                      Personally identifiable information may include, but is
                      not limited to:
                    </p>

                    <ul>
                      <li>Email address</li>
                      <li>Name</li>
                      <li>Date of Birth</li>
                    </ul>

                    <h4>Usage Data</h4>

                    <p>
                      We may also collect information that your browser sends
                      whenever you visit our Service or when you access the
                      Service by or through a mobile device ("Usage Data").
                    </p>
                    <p>
                      This Usage Data may include information such as your
                      computer's Internet Protocol address (e.g. IP address),
                      browser type, browser version, the pages of our Service
                      that you visit, the time and date of your visit, the time
                      spent on those pages, unique device identifiers and other
                      diagnostic data.
                    </p>
                    <p>
                      When you access the Service by or through a mobile device,
                      this Usage Data may include information such as the type
                      of mobile device you use, your mobile device unique ID,
                      the IP address of your mobile device, your mobile
                      operating system, the type of mobile Internet browser you
                      use, unique device identifiers and other diagnostic data.
                    </p>

                    <h4>Tracking & Cookies Data</h4>
                    <p>
                      We use cookies and similar tracking technologies to track
                      the activity on our Service and hold certain information.
                    </p>
                    <p>
                      Cookies are files with small amount of data which may
                      include an anonymous unique identifier. Cookies are sent
                      to your browser from a website and stored on your device.
                      Tracking technologies also used are beacons, tags, and
                      scripts to collect and track information and to improve
                      and analyze our Service.
                    </p>
                    <p>
                      You can instruct your browser to refuse all cookies or to
                      indicate when a cookie is being sent. However, if you do
                      not accept cookies, you may not be able to use some
                      portions of our Service.
                    </p>

                    <h2>Use of Data</h2>

                    <p>HushUP uses the collected data for various purposes:</p>
                    <ul>
                      <li>To provide and maintain the Service</li>
                      <li>To notify you about changes to our Service</li>
                      <li>
                        To allow you to participate in interactive features of
                        our Service when you choose to do so
                      </li>
                      <li>To provide customer care and support</li>
                      <li>
                        To provide analysis or valuable information so that we
                        can improve the Service
                      </li>
                      <li>To monitor the usage of the Service</li>
                      <li>To detect, prevent and address technical issues</li>
                    </ul>

                    <h2>Transfer Of Data</h2>
                    <p>
                      Your information, including Personal Data, may be
                      transferred to — and maintained on — computers located
                      outside of your state, province, country or other
                      governmental jurisdiction where the data protection laws
                      may differ than those from your jurisdiction.
                    </p>
                    <p>
                      Your consent to this Privacy Policy followed by your
                      submission of such information represents your agreement
                      to that transfer.
                    </p>
                    <p>
                      HushUP will take all steps reasonably necessary to ensure
                      that your data is treated securely and in accordance with
                      this Privacy Policy and no transfer of your Personal Data
                      will take place to an organization or a country unless
                      there are adequate controls in place including the
                      security of your data and other personal information.
                    </p>

                    <h2>Deletion Of Data</h2>
                    <p>
                      At this point, data deletion can be requested by sending
                      us a mail at hushupapp@gmail.com with subject{" "}
                      <i>
                        <b>Data deletion request</b>
                      </i>
                      . Kindly mention your username while requesting. Make sure
                      to mail from the registered email address.
                    </p>

                    <h2>Disclosure Of Data</h2>

                    <h3>Legal Requirements</h3>
                    <p>
                      HushUP may disclose your Personal Data in the good faith
                      belief that such action is necessary to:
                    </p>
                    <ul>
                      <li>To comply with a legal obligation</li>
                      <li>
                        To protect and defend the rights or property of HushUP
                      </li>
                      <li>
                        To prevent or investigate possible wrongdoing in
                        connection with the Service (Internal)
                      </li>
                      <li>
                        To protect the personal safety of users of the Service
                        or the public as and when demand by competent authority
                      </li>
                      <li>To protect against legal liability</li>
                    </ul>

                    <h2>Security Of Data</h2>
                    <p>
                      The security of your data is important to us, but remember
                      that no method of transmission over the Internet, or
                      method of electronic storage is 100% secure. While we
                      strive to use commercially acceptable means to protect
                      your Personal Data, we cannot guarantee its absolute
                      security.
                    </p>

                    <h2>Service Providers</h2>
                    <p>
                      We may employ third party companies and individuals to
                      facilitate our Service ("Service Providers"), to provide
                      the Service on our behalf, to perform Service-related
                      services or to assist us in analyzing how our Service is
                      used.
                    </p>
                    <p>
                      These third parties have access to your Data only to
                      perform these tasks on our behalf and are obligated not to
                      disclose or use it for any other purpose.
                    </p>

                    <h3>Analytics</h3>
                    <p>
                      We may use third-party Service Providers to monitor and
                      analyze the use of our Service.
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>Firebase</strong>
                        </p>
                        <p>
                          Firebase is a web service suite offered by Google that
                          we use to collect analytics and software health data.
                          Google uses the data collected to track and monitor
                          the use of our Service.
                        </p>
                      </li>
                    </ul>

                    <h2>Links To Other Sites</h2>
                    <p>
                      Our Service may contain links to other sites that are not
                      operated by us. If you click on a third party link, you
                      will be directed to that third party's site. We strongly
                      advise you to review the Privacy Policy of every site you
                      visit.
                    </p>
                    <p>
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services.
                    </p>

                    <h2>Changes To This Privacy Policy</h2>
                    <p>
                      We may update our Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page.
                    </p>
                    <p>
                      We will let you know via email and/or a prominent notice
                      on our Service, prior to the change becoming effective and
                      update the "effective date" at the top of this Privacy
                      Policy.
                    </p>
                    <p>
                      You are advised to review this Privacy Policy periodically
                      for any changes. Changes to this Privacy Policy are
                      effective when they are posted on this page.
                    </p>

                    <h2>Contact Us</h2>
                    <p>
                      If you have any questions about this Privacy Policy,
                      please contact us:
                    </p>
                    <ul>
                      <li>By email: hushupapp@gmail.com</li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer quickLinks={true} />
    </Grid>
  );
};

export default PrivacyPolicy;
