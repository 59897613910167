export class UserPublic {
  private _id!: string;
  private _username!: string;
  private _fullName!: string;
  private _shortBio!: string;
  private _appOnly!: boolean;
  private _messageAppOnly!: boolean;
  private _version!: string;
  private _messages: any[] = [];

  toJSON() {}

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  get shortBio(): string {
    return this._shortBio;
  }

  set shortBio(value: string) {
    this._shortBio = value;
  }

  get appOnly(): boolean {
    return this._appOnly;
  }

  set appOnly(value: boolean) {
    this._appOnly = value;
  }

  get messageAppOnly(): boolean {
    return this._messageAppOnly;
  }

  set messageAppOnly(value: boolean) {
    this._messageAppOnly = value;
  }

  get version(): string {
    return this._version;
  }

  set version(value: string) {
    this._version = value;
  }

  get messages(): any[] {
    return this._messages;
  }

  set messages(value: any[]) {
    this._messages = value;
  }
}
