import React from "react";
import { useAuth } from "./contexts/AuthContext";
import { Navigate, RouteProps } from "react-router-dom";

interface Props {
  path?: RouteProps["path"];
  component: React.ReactElement;
}

const PrivateRoutes: React.FC<Props> = ({ path, component, ...props }) => {
  const { token } = useAuth();

  return token ? component : <Navigate to="/login" />;
};

export default PrivateRoutes;
