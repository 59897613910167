import React, { useEffect, useState } from "react";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  Alert,
  AlertColor,
  Snackbar,
} from "@mui/material";
import LinearProgress from "../../utils/ui/LinearProgress";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { makeStyles } from "@mui/styles";
import { UserPublic } from "../../codelibrary/Models/UserPublic";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Settings from "../settings/Settings";
import MessageListView from "../components/MessageListView";
import {
  getStickers,
  getReceivedMessages,
  patchMe,
  updateMessagePrivacy,
} from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import { useApp } from "../../contexts/AppContext";
import { ApiResponse } from "../../codelibrary/Models/ApiResponse";
import MessageView from "../components/MessageView";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import * as Sentry from "@sentry/browser";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import StickerListView from "../components/StickerListView";
import StickerView from "../components/StickerView";

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    flexGrow: "inherit",
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  textWhite: {
    color: theme.palette.primary.contrastText,
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.secondary.main,
  },
  profileBox: {},
  bodyMargin: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
  profileCard: {
    borderRadius: "0px",
    backgroundColor: "beige",
  },
}));

interface Props {
  profile?: UserPublic;
}

const Home: React.FC<Props> = ({ profile, ...props }) => {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const [bottomNavValue, setBottomNavValue] = React.useState(
    process.env.REACT_APP_INITIAL_OPEN === "sticker" ? 1 : 0
  );
  const { logout, updateUser, token } = useAuth();
  const {
    appUser,
    updateAppUser,
    messages,
    stickers,
    setMessages,
    setStickers,
  } = useApp();
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const [response, setResponse] = useState<{
    status: AlertColor;
    message: string;
  }>({ status: "success", message: "" });
  const [linkPath, setLinkPath] = useState<string>("");
  const [selectedSticker, setSelectedSticker] = useState<any>();

  useEffect(() => {
    if (process.env.REACT_APP_INITIAL_OPEN === "sticker") {
      history("stickers");
      setLinkPath("/sticker");
    } else {
      history("received");
    }
  }, []);

  useEffect(() => {
    if (appUser) {
      const slug = location.pathname.split("/");
      if (slug === undefined) {
        history("received");
        if (messages.length === 0) {
          setBottomNavValue(0);
          getReceivedMessages(appUser?.id, token)
            .then((res) => {
              setMessages(res.data);
            })
            .catch((err: ApiResponse) => {
              handleError(err);
            });
        }
        setBottomNavValue(0);
      } else if (appUser !== undefined) {
        const route = slug[2];
        switch (route) {
          case "stickers":
            setBottomNavValue(1);
            if (stickers.length === 0) {
              getStickers(appUser?.id, token)
                .then((res) => {
                  setStickers(res.data);
                })
                .catch((err: ApiResponse) => {
                  handleError(err);
                });
            }
            break;
          case "received":
            if (messages.length === 0) {
              setBottomNavValue(0);
              getReceivedMessages(appUser?.id, token)
                .then((res) => {
                  setMessages(res.data);
                })
                .catch((err: ApiResponse) => {
                  handleError(err);
                });
            }
            break;
          case "settings":
            setBottomNavValue(2);
            break;
        }
      }
    }
  }, [appUser, location.pathname]);

  const handlePrivacyUpdate = (appOnly: boolean) => {
    if (appOnly === profile?.appOnly) {
      setOpen(false);
      return;
    }
    patchMe(
      {
        appOnly: appOnly,
      },
      appUser.id,
      token
    )
      .then((res) => {
        const patchData = res.patchData;
        const user = {
          ...appUser,
          ...patchData,
        };
        updateAppUser(user);
        setOpen(false);
      })
      .catch((err: ApiResponse) => {
        handleError(err);
      });
  };

  const handleMessageSelected = (id: string) => {
    setSelectedMessage(messages.find((message: any) => message.id === id));
    history(`received/${id}`);
  };

  const handleMessagePrivacyToggled = (mode: number, messageId: string) => {
    updateMessagePrivacy(
      messageId,
      {
        username: appUser.username,
        privacyStatus: mode,
      },
      token,
      appUser.id
    )
      .then((res: ApiResponse) => {
        messages.forEach((message: any) => {
          if (message.id === messageId) {
            message.privacyStatus = mode;
            setSelectedMessage({
              ...message,
            });
            setResponse({
              message: "Message privacy updated!",
              status: "info",
            });
            setSnackbarOpen(true);
          }
        });
      })
      .catch((err: any) => {
        handleError(err);
      });
  };

  const handleError = (err: ApiResponse) => {
    setResponse({
      message: err.msg,
      status: "error",
    });
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMessageReported = (id: string) => {
    setMessages([...messages.filter((message: any) => message.id !== id)]);
    setResponse({
      message: "Message reported",
      status: "success",
    });
    setSnackbarOpen(true);
  };

  const handleLogoutClicked = () => {
    logout()
      .then(() => {
        updateUser(undefined);
        updateAppUser(undefined);
        setMessages([]);
        setStickers([]);
        history("/login");
      })
      .catch((err: any) => {
        Sentry.captureException(err);
      });
  };

  const handleStickerSelected = (id: string) => {
    setSelectedSticker(stickers.find((sticker: any) => sticker.id === id));
    history(`stickers/${id}`);
  };

  const getLink = (username?: string, path?: string) => {
    return path! ? `hushup.app/${username}${path}` : `hushup.app/${username}`;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.bodyMargin}
    >
      <Grid item xl={4} lg={6} md={8} sm={8} xs={12} alignItems="center">
        <Card
          className={`${classes.root} ${classes.profileCard}`}
          sx={{ borderRadius: "0px" }}
        >
          <CardContent>
            {profile === undefined && (
              <LinearProgress variant={"indeterminate"} />
            )}
            {profile !== undefined && (
              <Grid container justifyContent={"center"}>
                <Grid container item xs={12} justifyContent={"space-around"}>
                  <Grid container item xs={4} justifyContent={"center"}>
                    <Avatar
                      alt={profile.fullName}
                      src="/broken-image.jpg"
                      sx={{ width: 64, height: 64 }}
                    />
                  </Grid>
                  <Grid container item xs={8} paddingLeft={2}>
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom display="inline">
                        {profile.fullName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        display="inline"
                      >
                        @{profile.username}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" gutterBottom>
                        {profile.shortBio}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {profile !== undefined && (
              <Button
                variant="contained"
                endIcon={<ShareOutlinedIcon />}
                fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "4px",
                  textTransform: "none",
                }}
                onClick={() => {
                  let link = `hushup.app/${profile.username}${linkPath}`;
                  if (bottomNavValue === 1) {
                    link = `Hey, want to send a sticker? Click here
                                        ${link}`;
                  } else {
                    link = `Hey, want to say something anonymously? Click here
                                        ${link}`;
                  }
                  navigator.clipboard.writeText(link);
                  setResponse({
                    message: "Link copied to clipboard",
                    status: "success",
                  });
                  setSnackbarOpen(true);
                }}
              >
                hushup.app/{profile.username}
                {linkPath}
              </Button>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={4} lg={6} md={8} sm={8} xs={12} alignItems="center">
        <Routes>
          <Route
            path="received/:id"
            element={
              <MessageView
                message={selectedMessage}
                onPrivacyToggled={handleMessagePrivacyToggled}
                onMessageReported={handleMessageReported}
                onError={handleError}
              />
            }
          />
          <Route
            path="received/*"
            element={
              <MessageListView
                mode={0}
                messages={messages}
                onSelected={handleMessageSelected}
                userLink={getLink(profile?.username, linkPath)}
              />
            }
          />
          <Route
            path="settings"
            element={
              <Settings
                profile={profile!}
                togglePrivacyClicked={() => setOpen(true)}
                onLogoutClicked={handleLogoutClicked}
              />
            }
          />
          <Route
            path="stickers/*"
            element={
              <StickerListView
                stickers={stickers}
                onSelected={handleStickerSelected}
                userLink={getLink(profile?.username, linkPath)}
              />
            }
          />
          <Route
            path="stickers/:id"
            element={<StickerView sticker={selectedSticker} />}
          />
        </Routes>
      </Grid>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={bottomNavValue}
          onChange={(event, newValue) => {
            switch (newValue) {
              case 0:
                setLinkPath("");
                history("received");
                break;
              case 1:
                setLinkPath("/sticker");
                history("stickers");
                break;
              case 2:
                setLinkPath("");
                history("settings");
                break;
            }
            setBottomNavValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Messages"
            icon={<ChatOutlinedIcon />}
          />
          <BottomNavigationAction
            label="Stickers"
            icon={<CardGiftcardOutlinedIcon />}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<SettingsOutlinedIcon />}
          />
        </BottomNavigation>
      </Paper>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Privacy Mode</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            On enabling <b>App Only</b> mode, only those users who have a HushUP
            account will be able to send you messages. This may help you avoid
            spam messages but remember, this may also result in getting less
            number of messages. When privacy is set to <b>Public</b>, any user
            can send you message without creating an account or downloading the
            app.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handlePrivacyUpdate(true)}>App Only</Button>
          <Button onClick={() => handlePrivacyUpdate(false)} autoFocus>
            Public
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={response.status}
          sx={{ width: "100%" }}
        >
          {response.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Home;
