import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import FormTextField from "./FormTextField";
import FormPasswordField from "./FormPasswordField";
import { NavigateNextOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

interface FormValues {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
}

const LoginForm: React.FC<Props> = ({ onSubmit, ...props }) => {
  const validationSchema = yup.object().shape({
    email: yup.string().email("Enter valid email").required("Required"),
    password: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form noValidate autoComplete="off">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{ backgroundColor: "#fff" }}
          >
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              xs={10}
            >
              <Grid item xs={12}>
                <Field
                  name="email"
                  label="Email"
                  size="small"
                  type="email"
                  component={FormTextField}
                />
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Field
                  name="password"
                  label="Password"
                  size="small"
                  type="password"
                  component={FormPasswordField}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                marginTop={4}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color={"primary"}
                  loading={formikProps.isSubmitting}
                  endIcon={<NavigateNextOutlined />}
                  loadingPosition="end"
                >
                  Submit
                </LoadingButton>
              </Grid>
              <Grid
                container
                item
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>
                  <Link href="/forgot-password">Forgot Password?</Link>
                </Typography>
                <Typography>
                  Need an account? <Link href="/signup">Sign Up</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
