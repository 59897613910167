import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { getMe } from "../utils/api";
import { useApp } from "../contexts/AppContext";
import { Route, Routes, useNavigate } from "react-router-dom";
import UserProfile from "./settings/UserProfile";
import Home from "./home/Home";
import Username from "./settings/Username";
import { ApiResponse } from "../codelibrary/Models/ApiResponse";
import UserProfileCreate from "./settings/UserProfileCreate";

interface Props {}

const Me: React.FC<Props> = () => {
  const { appUser, updateAppUser, setMessages, setStickers } = useApp();
  const { token, logout, updateUser } = useAuth();
  const history = useNavigate();

  useEffect(() => {
    getMe(token)
      .then((res: ApiResponse) => {
        if (!res.data.version || res.data.version < 2) {
          logout()
            .then(() => {
              updateUser(undefined);
              updateAppUser(undefined);
              setMessages([]);
              setStickers([]);
              history("/login/app");
            })
            .catch((err: any) => {});
        }
        updateAppUser(res.data);
        if (!res.data.fullName || !res.data.shortBio) {
          history("profile");
        }
      })
      .catch((err: ApiResponse) => {
        if (err.status === 404) {
          history("create-profile");
        }
      });
  }, [token]);

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xl={4} lg={6} md={8} sm={8} xs={12}>
        <Routes>
          <Route path="/*" element={<Home profile={appUser!} />} />
          <Route path="profile" element={<UserProfile />} />
          <Route path="username" element={<Username />} />
          <Route path="create-profile" element={<UserProfileCreate />} />
        </Routes>
      </Grid>
    </Grid>
  );
};

export default Me;
