import React, { useState } from "react";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormTextField from "./FormTextField";
import { SendOutlined } from "@mui/icons-material";

interface FormValues {
  message: string;
}

interface Props {
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
}

const ReplyForm: React.FC<Props> = ({ onSubmit, ...props }) => {
  const [message, setMessage] = useState("");

  const validationSchema = yup.object().shape({
    message: yup
      .string()
      .max(1000, "Maximum 1000 characters")
      .required("Required"),
  });

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const val = (e.target as HTMLInputElement).value;
    setMessage(val);
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Formik
        initialValues={{
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps: FormikProps<FormValues>) => (
          <Form
            noValidate
            autoComplete="off"
            onChange={(e) => handleChange(e)}
            style={{ width: "100%" }}
          >
            <Grid
              container
              item
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={9}>
                <Field
                  name="message"
                  label="Reply"
                  component={FormTextField}
                  multiline
                  fullWidth
                  rows={1}
                  variant="outlined"
                  inputProps={{ maxLength: 1000 }}
                  placeholder={"Type your reply here"}
                />
              </Grid>
              <Grid
                container
                item
                xs={3}
                justifyContent={"flex-end"}
                alignItems="center"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color={"primary"}
                  loading={formikProps.isSubmitting}
                  endIcon={<SendOutlined />}
                  loadingPosition="end"
                >
                  send
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={9} alignItems={"center"}>
                <Typography
                  style={{ fontSize: "smaller" }}
                  align="right"
                  variant={"subtitle2"}
                >
                  {message.length}/1000
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default ReplyForm;
