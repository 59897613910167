import React, { useContext, useState, useEffect } from "react";

export const AppContext = React.createContext<any>("");

export const useApp = () => {
  return useContext(AppContext);
};

interface Props {
  children: any;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [appUser, setAppUser] = useState();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<any[]>([]);
  const [stickers, setStickers] = useState<any[]>([]);

  const updateAppUser = (user: any) => {
    setAppUser(user);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const value = {
    appUser,
    updateAppUser,
    messages,
    setMessages,
    stickers,
    setStickers,
  };

  return (
    <AppContext.Provider value={value}>
      {!loading && children}
    </AppContext.Provider>
  );
};
