import React from "react";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { NavigateNextOutlined } from "@mui/icons-material";
import FormPasswordField from "../FormPasswordField";

interface FormValues {
  password: string;
}

interface Props {
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
}

const PasswordForm: React.FC<Props> = ({ onSubmit, ...props }) => {
  const validationSchema = yup.object().shape({
    password: yup.string().min(6, "Minimum 6 characters").required("Required"),
  });

  return (
    <Grid
      container
      item
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      xs={10}
    >
      <Grid item xs={12}>
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              <Grid
                container
                item
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Field
                    name="password"
                    label="Password"
                    size="small"
                    type="password"
                    component={FormPasswordField}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                  marginTop={4}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color={"primary"}
                    loading={formikProps.isSubmitting}
                    endIcon={<NavigateNextOutlined />}
                    loadingPosition="end"
                  >
                    Next
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default PasswordForm;
