import axios from "axios";
import { ApiResponse } from "../codelibrary/Models/ApiResponse";
import { UserPublic } from "../codelibrary/Models/UserPublic";
import * as Sentry from "@sentry/browser";

export const pingifyMessage = (publicId: string) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_PINGIFY_URL}/ping`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      public_id: publicId,
    }),
  })
    .then((res) => {
      return res.data;
    })
    .then((data) => {})
    .catch((err) => {});
};

export const getUserInfo = async () => {
  return await axios({
    url: process.env.REACT_APP_USER_INFO_URL,
  })
    .then((res) => res)
    .catch((err) => {
      Sentry.captureException(err);
      throw err;
    });
};

export const getUserPublicProfile = async (userId: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/user/${userId}`,
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      return data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.msg;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const sendMessage = async (
  userId: string,
  message: string,
  userInfo: any,
  profile: UserPublic
) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/user/${userId}/message`,
    headers: {
      "Content-Type": "application/json",
      "x-user-info": userInfo,
    },
    data: JSON.stringify({
      message: message,
      id: profile.id,
      username: profile.username,
      version: profile.version,
    }),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return res;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.msg;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const postMe = async (
  username: string,
  fullName: string,
  shortBio: string,
  token: string
) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/me/profile`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: JSON.stringify({
      username: username,
      fullName: fullName,
      shortBio: shortBio,
    }),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return data.data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const patchMe = async (data: any, userId: string, token: string) => {
  return await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/me/profile`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-user-id": userId,
    },
    data: JSON.stringify(data),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return data.data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const getMe = (token: string) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_USER_PROFILE_URL,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      return data;
    })
    .catch((err) => {
      let data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const getReceivedMessages = (userId: string, token: string) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/me/messages/received?after=0`,
    headers: {
      "x-user-id": `${userId}`,
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      return data;
    })
    .catch((err) => {
      let data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const checkUsernameAvailability = async (username: string) => {
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/me/username-availability/${username}`,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const patchUsername = (
  username: string,
  userId: string,
  token: string
) => {
  return axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/me/username`,
    headers: {
      "x-user-id": `${userId}`,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      username: `${username}`,
    }),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return data.data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const updateMessagePrivacy = async (
  messageId: string,
  data: any,
  token: string,
  userId: string
) => {
  return await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/me/messages/${messageId}/privacy`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-user-id": userId,
    },
    data: JSON.stringify(data),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return data.data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const getReplies = async (
  parentId: string,
  token: string,
  userId: string
) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/me/messages/${parentId}/replies`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-user-id": `${userId}`,
    },
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      return data;
    })
    .catch((err) => {
      let data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const postReply = async (
  data: any,
  parentId: string,
  token: string,
  userId: string
) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/me/messages/${parentId}/reply`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-user-id": `${userId}`,
    },
    data: JSON.stringify(data),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return data.data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const reportMessage = async (
  data: { reportOption: string; username: string },
  parentId: string,
  token: string,
  userId: string
) => {
  return await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/me/messages/${parentId}/report`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-user-id": `${userId}`,
    },
    data: JSON.stringify(data),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return data.data;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const getStickers = async (userId: string, token: string) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/me/stickers`,
    headers: {
      "x-user-id": `${userId}`,
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      return data;
    })
    .catch((err) => {
      let data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.tag;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};

export const sendSticker = async (userId: string, userInfo: any, data: any) => {
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/user/${userId}/sticker`,
    headers: {
      "Content-Type": "application/json",
      "x-user-info": userInfo,
    },
    data: JSON.stringify(data),
  })
    .then((res) => {
      const data = new ApiResponse();
      data.status = res.status;
      data.data = res.data;
      data.msg = res.data.msg;
      return res;
    })
    .catch((err) => {
      const data = new ApiResponse();
      data.status = err.response.status;
      data.error = err.response.data.msg;
      data.msg = err.response.data.msg;
      Sentry.captureException(data);
      throw data;
    });
};
