import React, { useState } from "react";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { Box, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormTextField from "./FormTextField";
import { SendOutlined } from "@mui/icons-material";

interface FormValues {
  topic: string;
  textBody: string;
  textTo: string;
  textFrom: string;
}

interface Props {
  sticker: any;
  handleSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
  comp: React.ReactNode;
}

const StickerForm: React.FC<Props> = ({
  sticker,
  handleSubmit,
  comp,
  ...props
}) => {
  const validationSchema = yup.object().shape({
    textBody: yup
      .string()
      .max(30, "Maximum 30 characters")
      .required("Required"),
    textTo: yup.string().max(15, "Maximum 15 characters"),
    textFrom: yup.string().max(15, "Maximum 15 characters"),
  });

  return (
    <Formik
      initialValues={{
        textBody: sticker.defaultText,
        textFrom: "",
        textTo: "",
        topic: sticker.topic,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              xs={10}
            >
              <Grid item xs={12}>
                <Field
                  name="textTo"
                  label="To (Optional)"
                  component={FormTextField}
                  multiline
                  fullWidth
                  rows={1}
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  placeholder={"To"}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="textBody"
                  label="Message"
                  component={FormTextField}
                  multiline
                  fullWidth
                  rows={2}
                  variant="outlined"
                  inputProps={{ maxLength: 30 }}
                  placeholder={"Type your message here"}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="textFrom"
                  label="From (Optional)"
                  component={FormTextField}
                  multiline
                  fullWidth
                  rows={1}
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  placeholder={"From"}
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={8} alignItems={"center"}>
                  <Typography
                    variant={"subtitle2"}
                    color={"textPrimary"}
                    style={{ fontSize: "smaller" }}
                    align={"left"}
                  >
                    Message Responsibly!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {comp}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                marginTop={4}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color={"primary"}
                  loading={isSubmitting}
                  endIcon={<SendOutlined />}
                  loadingPosition="end"
                >
                  Send
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default StickerForm;
