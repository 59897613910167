import React, { useState } from "react";
import { FieldProps, getIn } from "formik";
import {
  TextFieldProps,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const FormPasswordField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { error, helperText, field, form, type, ...rest } = props;

  const handleMaskToggleClick = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
  };

  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
      }
      style={{ width: "100%" }}
      type={showPassword ? "text" : type}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={showPassword ? "Hide Password" : "Show Password"}>
              <IconButton
                onClick={handleMaskToggleClick}
                onMouseDown={handleMouseDown}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...rest}
      {...field}
    />
  );
};

export default FormPasswordField;
