import React from "react";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Box } from "@mui/system";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  messageText: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  userLink: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingLeft: "4px",
    paddingRight: "4px",
    borderRadius: "8px",
    display: "inline-flex",
  },
}));

interface Props {
  mode: 0 | 1;
  messages: any[];
  userLink?: string;
  onSelected: (id: string) => void;
}

const MessageListView: React.FC<Props> = ({
  mode,
  messages,
  onSelected,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      {messages.length > 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 700,
            "& ul": { padding: 0 },
          }}
          subheader={
            <ListSubheader
              sx={{
                backgroundColor: mode === 0 ? "#f8f8f8" : "defaultColor",
                fontWeight: "bold",
              }}
            >
              {mode === 0 ? "Received Messages" : "Replies"}
            </ListSubheader>
          }
        >
          {messages
            .filter((message) => message.isReported !== true)
            .sort((a, b) => (+a.timestamp > +b.timestamp ? -1 : 1))
            .map((value, index, array) => {
              return (
                <>
                  <ListItem
                    onClick={() => {
                      onSelected(`${value.id}`);
                    }}
                  >
                    <ListItemText
                      id="list-label-message"
                      primary={
                        <Typography className={classes.messageText}>
                          {value.body}
                        </Typography>
                      }
                      secondary={moment(+value.timestamp).format("Do MMMM 'YY")}
                    />
                  </ListItem>
                  <Divider variant="fullWidth" />
                </>
              );
            })}
        </List>
      )}
      {messages.length === 0 && mode === 0 && (
        <EmptyReceivedMessages {...props} />
      )}
      {messages.length === 0 && mode === 1 && <EmptyReplies />}
    </Grid>
  );
};

export default MessageListView;

interface EmptyMessagesProps {
  userLink?: string;
}

const EmptyReceivedMessages: React.FC<EmptyMessagesProps> = ({
  userLink,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          maxHeight: 500,
          "& ul": { padding: 0 },
        }}
        subheader={
          <ListSubheader
            sx={{
              backgroundColor: "#f8f8f8",
              fontWeight: "bold",
            }}
          >
            Received Messages
          </ListSubheader>
        }
      ></List>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
        flexGrow={1}
        sx={{
          width: "80%",
        }}
      >
        <Grid container item direction="column" xs={8}>
          <Typography color="textSecondary" align="center">
            <EmailOutlinedIcon />
          </Typography>
          <Typography color="textSecondary" align="center">
            No messages received yet!
          </Typography>
          <Typography color="textSecondary" align="center">
            Tap on your link&nbsp;
            <Typography align="center" className={classes.userLink}>
              {userLink}
            </Typography>
            &nbsp;to copy and share on WhatsApp, Insta, FB so your friends can
            see. Keep Hushing!
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

const EmptyReplies = () => {
  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          maxHeight: 500,
          "& ul": { padding: 0 },
        }}
        subheader={
          <ListSubheader
            sx={{
              backgroundColor: "defaultColor",
              fontWeight: "bold",
            }}
          >
            Replies
          </ListSubheader>
        }
      ></List>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid xs={12}>
          <Typography color="textSecondary" align="center">
            No replies yet!
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
