import React, { useState } from "react";
import {
  CssBaseline,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Transition from "../../utils/ui/Transition";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import { useApp } from "../../contexts/AppContext";
import Lottie from "react-lottie-player";
import { Box } from "@mui/system";
import { stickerTopicMap } from "../../utils/constants";
import { getAnimationByTopic } from "../../utils/lottie/helper";

interface Props {
  sticker: any;
}

const StickerView: React.FC<Props> = ({ sticker, ...props }) => {
  const [open, setOpen] = useState(true);
  const history = useNavigate();
  const { appUser } = useApp();

  const handleClose = () => {
    setOpen(false);
    history("../stickers");
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{
        padding: "0px",
      }}
    >
      <DialogTitle id="dialog-message-title">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <Typography>
              {stickerTopicMap[sticker.topic].defaultText}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={4}
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Lottie
            loop={false}
            animationData={getAnimationByTopic(sticker.topic)}
            play
            style={{
              width: 250,
              height: 250,
            }}
          />
        </Box>
        <Grid container justifyContent="center">
          <Grid item xs={8} md={6} xl={4}>
            {sticker.textTo !== undefined && sticker.textTo !== "" && (
              <>
                <Typography align="left" variant="subtitle1" display="block">
                  To,
                </Typography>
                <Typography align="left" variant="subtitle2" display="block">
                  {sticker.textTo}
                </Typography>
              </>
            )}
            <Typography
              align="center"
              variant="subtitle1"
              display="block"
              fontStyle="italic"
            >
              {sticker.textBody}
            </Typography>
            {sticker.textFrom !== undefined && sticker.textFrom !== "" && (
              <>
                <Typography align="right" variant="subtitle1" display="block">
                  From,
                </Typography>
                <Typography align="right" variant="subtitle2" display="block">
                  {sticker.textFrom}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Typography align="center" variant="caption" display="block">
          hushup.app/{appUser.username}
        </Typography>
        <Divider variant="fullWidth" />
        <DialogContentText id="dialog-message-timestamp" marginTop={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography>
              {moment(+sticker?.timestamp).format("Do MMMM 'YY")}
            </Typography>
          </Grid>
        </DialogContentText>
        <CssBaseline />
      </DialogContent>
    </Dialog>
  );
};

export default StickerView;
