import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { PLAY_STORE_LINK } from "../codelibrary/utility/constants";
import { makeStyles } from "@mui/styles";
import SignupLinkComponent from "../utils/ui/SignupLinkComponent";
import SignupButtonComponent from "../utils/ui/SignupButtonComponent";

interface Props {}

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    flexGrow: "inherit",
  },
  coverText: {
    color: "#fff",
    textShadow: `2px 2px ${theme.palette.primary.main}`,
  },
}));

const Home: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Box alignContent={"center"}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        spacing={0}
      >
        <Grid item xs={12} alignItems="center">
          <Typography
            align={"center"}
            className={classes.coverText}
            variant={"h2"}
            style={{ fontWeight: 500 }}
          >
            HushUP
          </Typography>
        </Grid>
        <Grid item xs={12} alignItems="center">
          <Typography
            align={"center"}
            className={classes.coverText}
            variant={"h3"}
          >
            Anonymous feedback, gossip, confessions.
          </Typography>
        </Grid>
        <Grid item xs={12} alignItems="center">
          <Box>
            <SignupButtonComponent text={"Get Started"} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
