export const stickerTopicMap: any = {
  rose: {
    defaultText: "Here is a Rose for you!",
    linkTitle: "Send a Rose to",
    topic: "rose",
  },
  hug: {
    defaultText: "Here is a Hug for you!",
    linkTitle: "Send a Hug to",
    topic: "hug",
  },
  kiss: {
    defaultText: "Here is a Kiss for you!",
    linkTitle: "Send a Kiss to",
    topic: "kiss",
  },
  promise: {
    defaultText: "I have a promise for you!",
    linkTitle: "Make a promise to",
    topic: "promise",
  },
  propose: {
    defaultText: "I propose you!",
    linkTitle: "Propose to",
    topic: "propose",
  },
  teddy: {
    defaultText: "Here is a teddy for you!",
    linkTitle: "Send a teddy to",
    topic: "teddy",
  },
  valentine: {
    defaultText: "Happy Valentine's Day!",
    linkTitle: "Wish Valentine's Day to",
    topic: "valentine",
  },
};

export const stickerTopicList = [
  {
    defaultText: "Here is a Rose for you!",
    linkTitle: "Send a Rose to",
    topic: "rose",
  },
  {
    defaultText: "Here is a Hug for you!",
    linkTitle: "Send a Hug to",
    topic: "hug",
  },
  {
    defaultText: "Here is a Kiss for you!",
    linkTitle: "Send a Kiss to",
    topic: "kiss",
  },
  {
    defaultText: "I have a promise for you!",
    linkTitle: "Make a promise to",
    topic: "promise",
  },
  {
    defaultText: "I propose you!",
    linkTitle: "Propose to",
    topic: "propose",
  },
  {
    defaultText: "Here is a teddy for you!",
    linkTitle: "Send a teddy to",
    topic: "teddy",
  },
  {
    defaultText: "Happy Valentine's Day!",
    linkTitle: "Wish Valentine's Day to",
    topic: "valentine",
  },
];
