import React from "react";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "../footer/Footer";

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    flexGrow: "inherit",
  },
  infoHeader: {
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: theme.palette.secondary.main,
    zIndex: -1,
    "& h3": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  infoContent: {
    marginBottom: "1em",
  },
  bodyMargin: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
}));

interface Props {}

const About: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      className={classes.bodyMargin}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.infoHeader}
      >
        <Typography variant="h3" align="center" className={classes.infoHeader}>
          About
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          xl={4}
          lg={6}
          md={8}
          xs={10}
          sm={8}
          direction="column"
          alignItems="center"
          paddingTop={1}
        >
          <Card>
            <CardContent>
              <Grid
                container
                justifyContent="center"
                className={classes.infoContent}
              >
                <Grid item xs={12} direction="column" alignItems="center">
                  <Grid container>
                    <Grid item>
                      <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                        Make the most of anonymity. Empower conversations.
                        Improve yourself.
                      </Typography>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <br />
                    <Grid item paddingTop={2}>
                      <Typography variant="body1" align="justify">
                        Every now and then, we have an urge to say something to
                        someone without the setback of being judged for honest,
                        pure thoughts. Also, sometimes we want to know what
                        people think about us but they avoid saying it upfront,
                        well obviously out of the fear of judgement or sounding
                        rude, they drop the idea and we are kept from a useful
                        thought. This can be addressed by technology which is
                        ever evolving and becoming increasingly user oriented.
                        Software technology can help in providing a platform
                        which addresses the mentioned need. The platform needs
                        to robust, human-friendly and must have enough mobility
                        to provide uninterrupted service irrespective of local
                        environment.
                      </Typography>
                    </Grid>
                    <br />
                    <Grid item>
                      <Typography variant="body1" align="justify">
                        Here comes - HushUP. HushUP is a free platform for
                        sharing views, ideas, thoughts anonymously. HushUP
                        allows users to create unique link to their profile for
                        sharing and gathering feedbacks, reviews, thoughts from
                        their networks. This link can be shared over social
                        networks like WhatsApp, LinkedIn, Facebook, Instagram
                        etc. Users can receive messages from people on HushUP.
                        These messages will be completely anonymous. A salient
                        feature of HushUP is that, users can reply to messages
                        too. Rest assured, the sender’s identity will always be
                        anonymous.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer quickLinks={true} />
    </Grid>
  );
};

export default About;
