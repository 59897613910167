import { Card, CardContent, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import Footer from "../footer/Footer";

interface Props {}

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
    minHeight: 300,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    flexGrow: "inherit",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  infoHeader: {
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: theme.palette.secondary.main,
    zIndex: -1,
    "& h3": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  infoContent: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  footer: {
    color: theme.palette.text.primary,
    "& a": {
      color: "#606060",
    },
  },
  copyRight: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  bodyMargin: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
}));

const TermsOfService: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems={"center"}
      className={classes.bodyMargin}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.infoHeader}
      >
        <Typography variant="h3" align="center" className={classes.infoHeader}>
          Terms of Service
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          xl={4}
          lg={6}
          md={8}
          xs={10}
          sm={8}
          direction="column"
          alignItems="center"
          paddingTop={1}
        >
          <Card>
            <CardContent>
              <Grid
                container
                justifyContent="center"
                className={classes.infoContent}
              >
                <Grid item xs={12} direction="column" alignItems="center">
                  <div>
                    <Typography variant="body2" style={{ fontStyle: "italic" }}>
                      Effective date: May 27, 2021
                    </Typography>
                    <p>
                      HushUP ("us", "we", or "our") operates the
                      https://www.hushup.app website and the HushUP mobile
                      application (the "Service").
                    </p>

                    <p>
                      This page informs you of our terms of service regarding
                      the services we provide when you use our Service and the
                      choices you have associated with the services.
                    </p>
                    <ul>
                      <li>
                        Account deletion, content deletion, data deletion
                        request by users will be processed only if possible.
                      </li>
                      <li>
                        Access to account may be revoked if necessary for the
                        service.
                      </li>
                      <li>
                        Request to perform actions which is not currently
                        available on user service will be entertained at will.
                      </li>
                      <li>
                        User data may be migrated to different data storage
                        services when necessary for the service.
                      </li>
                      <li>
                        User identifiable data will be provided to law
                        enforcement agencies and competent authorities only on
                        their intimidation.
                      </li>
                      <li>
                        In case of issue with using the service, users are
                        expected to contact via our email: hushupapp@gmail.com
                        or our{" "}
                        <Link href={"../contact"}>social media channels</Link>.
                      </li>
                    </ul>
                    <h2>Changes To These Terms of Service</h2>
                    <p>
                      We may update our Terms of Service from time to time. We
                      will notify you of any changes by posting the new Terms of
                      Service on this page.
                    </p>
                    <p>
                      We will let you know via email and/or a prominent notice
                      on our Service, prior to the change becoming effective and
                      update the "effective date" at the top of this Terms of
                      Service.
                    </p>
                    <p>
                      You are advised to review these Terms of Service
                      periodically for any changes. Changes to these Terms of
                      Service are effective when they are posted on this page.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer quickLinks={true} />
    </Grid>
  );
};

export default TermsOfService;
