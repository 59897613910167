import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import SignUpForm from "../forms/SignUp/SignUpForm";
import { FormikHelpers } from "formik";
import firebase from "firebase/compat/app";
import { Route, Routes, useNavigate } from "react-router-dom";
import EmailForm from "../forms/SignUp/EmailForm";
import PasswordForm from "../forms/SignUp/PasswordForm";
import UsernameForm from "../forms/SignUp/UsernameForm";
import { checkUsernameAvailability, postMe } from "../utils/api";
import { ApiResponse } from "../codelibrary/Models/ApiResponse";
import { useApp } from "../contexts/AppContext";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as Sentry from "@sentry/browser";
import { Helmet } from "react-helmet";
interface FormValues {
  username: string;
  email: string;
  password: string;
}

interface FormValueUsername {
  username: string;
}

interface FormValuePassword {
  password: string;
}

interface FormValueEmail {
  email: string;
}

const PageMap = ["Signup", "Username", "Password", "Email"];
const PageDescriptionMap = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "Set a unique username which helps people identify you.",
  "Set a strong password.",
  "Enter a valid email address. It is required to reset your password in case you forget it.",
];

interface Props {}

const Register: React.FC<Props> = ({ ...props }) => {
  const getTitle = () => {
    let _title = document.title;
    _title = "Signup - Create your account | " + _title;
    return _title;
  };

  const { signup, updateUser } = useAuth();
  const { updateAppUser } = useApp();
  const history = useNavigate();
  const [formVal, setFormVal] = useState<FormValues>({
    username: "",
    email: "",
    password: "",
  });
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    setActivePage(1);
    history("username");
  }, []);

  const createAccount = (_username: string, user: firebase.User | null) => {
    user?.getIdToken().then((token) => {
      postMe(_username, "", "", token)
        .then(async (data) => {
          await updateAppUser(data);
          await updateUser(user);
        })
        .catch((err: ApiResponse) => {
          Sentry.captureException(err);
        });
    });
  };

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const _username = values.username.toLowerCase();
    let usernameAvialable = false;
    await checkUsernameAvailability(_username)
      .then((res: ApiResponse) => {
        if (res.status === 200) {
          formikHelpers.setErrors({
            username: "Username not available",
          });
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          usernameAvialable = true;
        } else {
          formikHelpers.setErrors({
            username: err.msg,
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
    if (!usernameAvialable) {
      return;
    }

    const _email = values.email;
    const _password = values.password;
    await signup(_email, _password)
      .then(async (userCredential: firebase.auth.UserCredential) => {
        createAccount(_username, userCredential.user);
      })
      .catch((e: any) => {
        const err = e as firebase.FirebaseError;
        switch (err.code) {
          case "auth/email-already-in-use":
            formikHelpers.setErrors({
              email: "Email already in use",
            });
            break;
          case "auth/weak-password":
            formikHelpers.setErrors({
              password: "Password weak! Try again",
            });
            break;
          case "auth/network-request-failed":
            formikHelpers.setErrors({
              password: "Network error. Please try later.",
            });
            break;
          default:
            formikHelpers.setErrors({
              password: "Some error occurred. Please try later.",
            });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const handleUsernameSubmit = async (
    values: FormValueUsername,
    formikHelpers: FormikHelpers<FormValueUsername>
  ) => {
    const _username = values.username.toLowerCase();
    await checkUsernameAvailability(_username)
      .then((res: ApiResponse) => {
        if (res.status === 200) {
          formikHelpers.setErrors({
            username: "Username not available",
          });
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          setFormVal({
            ...formVal,
            username: _username,
          });
          setActivePage(2);
          history("password");
        } else if (err.status === 400) {
          formikHelpers.setErrors({
            username: err.msg,
          });
        } else {
          formikHelpers.setErrors({
            username: "Some error occurred. Try later.",
          });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const handlePasswordSubmit = (
    values: FormValuePassword,
    formikHelpers: FormikHelpers<FormValuePassword>
  ) => {
    const _password = values.password;
    setFormVal({
      ...formVal,
      password: _password,
    });
    formikHelpers.setSubmitting(false);
    setActivePage(3);
    history("email");
  };

  const handleEmailSubmit = (
    values: FormValueEmail,
    formikHelpers: FormikHelpers<FormValueEmail>
  ) => {
    const _email = values.email;
    setFormVal({
      ...formVal,
      email: _email,
    });
    formikHelpers.setSubmitting(false);
    setActivePage(0);
    history("");
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage:
              "url(https://storage.googleapis.com/hushup-assets-public/bg-signup.webp)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
            flexDirection="column"
            minHeight="100vh"
            style={{ backgroundColor: "#fff" }}
          >
            {activePage > 0 && (
              <Grid
                container
                item
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
                xs={10}
              >
                <Grid item>
                  <Typography variant="h3">{PageMap[activePage]}</Typography>
                  <Typography variant="body1">
                    {PageDescriptionMap[activePage]}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Routes>
              <Route
                path="/"
                element={
                  <SignUpForm onSubmit={handleSubmit} initialValues={formVal} />
                }
              />
              <Route
                path="/username"
                element={
                  <UsernameForm
                    initialValues={{
                      username: "",
                    }}
                    onSubmit={handleUsernameSubmit}
                  />
                }
              />
              <Route
                path="/password"
                element={<PasswordForm onSubmit={handlePasswordSubmit} />}
              />
              <Route
                path="/email"
                element={<EmailForm onSubmit={handleEmailSubmit} />}
              />
            </Routes>
            {activePage > 0 && (
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setActivePage(0);
                  history("");
                }}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
