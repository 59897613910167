import React, { useEffect } from "react";

interface Props {
  test?: "on";
}

const InArticle: React.FC<Props> = ({ test, ...props }) => {
  useEffect(() => {
    const p: any = {};

    try {
      if (typeof window === "object") {
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(
          p
        );
      }
    } catch {
      // Pass
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", textAlign: "center" }}
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client="ca-pub-6165242195196309"
      data-ad-slot="2307042137"
      data-adtest={test}
    />
  );
};

export default InArticle;
