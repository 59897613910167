import React, { useState } from "react";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormTextField from "./FormTextField";
import { SendOutlined } from "@mui/icons-material";

enum ReportOptions {
  "THREAT",
  "BULLYING",
}

interface FormValues {
  selectedOption: string;
}

interface Props {
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
}

const ReportForm: React.FC<Props> = ({ onSubmit, ...props }) => {
  const [message, setMessage] = useState("");

  const validationSchema = yup.object().shape({
    selectedOption: yup.string().required("Required"),
  });

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const val = (e.target as HTMLInputElement).value;
    setMessage(val);
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Formik
        initialValues={{
          selectedOption: ReportOptions.THREAT.toString(),
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form
            noValidate
            autoComplete="off"
            onChange={(e) => handleChange(e)}
            style={{ width: "100%" }}
          >
            <DialogContent>
              <FormControl component="fieldset">
                <FormLabel component="legend">Selected Option</FormLabel>
                <RadioGroup
                  name="selectedOption"
                  value={values.selectedOption.toString()}
                  onChange={(event) => {
                    setFieldValue("selectedOption", event.currentTarget.value);
                  }}
                >
                  <FormControlLabel
                    value={ReportOptions.THREAT.toString()}
                    control={<Radio />}
                    label="Threat"
                  />
                  <FormControlLabel
                    value={ReportOptions.BULLYING.toString()}
                    control={<Radio />}
                    label="Bullying"
                  />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                type="submit"
                variant="contained"
                color={"primary"}
                loading={isSubmitting}
                endIcon={<SendOutlined />}
                loadingPosition="end"
              >
                submit
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default ReportForm;
