import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReplyForm from "../../forms/ReplyForm";
import { FormikHelpers } from "formik";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MessageListView from "./MessageListView";
import { getReplies, postReply, reportMessage } from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import { useApp } from "../../contexts/AppContext";
import { ApiResponse } from "../../codelibrary/Models/ApiResponse";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import ReportForm from "../../forms/ReportForm";
import Transition from "../../utils/ui/Transition";

interface FormValues {
  message: string;
}

interface ReportFormValues {
  selectedOption: string;
}

interface Props {
  message: any;
  onPrivacyToggled: (mode: number, messageId: string) => void;
  onError: (err: ApiResponse) => void;
  onMessageReported?: (messageId: string) => void;
}

const MessageView: React.FC<Props> = ({
  message,
  onPrivacyToggled,
  onMessageReported,
  onError,
  ...props
}) => {
  const { token } = useAuth();
  const { appUser } = useApp();
  const history = useNavigate();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const privacyToggleOpen = Boolean(anchorEl);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuOpen = Boolean(menuAnchorEl);
  const [replies, setReplies] = useState<any[]>([]);
  const [openReportDialog, setOpenReportDialog] = useState(false);

  useEffect(() => {
    getReplies(message.id, token, appUser.id)
      .then((res) => {
        setReplies(res.data);
      })
      .catch((err: ApiResponse) => {
        onError(err);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
    history("../received");
  };

  const handlePrivacyToggleClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePrivacyToggleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (message === undefined) {
      handleClose();
    }
  }, []);

  const handlePrivacyToggled = (mode: number) => {
    if (mode !== message.privacyStatus) {
      onPrivacyToggled(mode, message.id);
    }
    handlePrivacyToggleClose();
  };

  const handleReplySelected = (id: string) => {};

  const handleSubmitReply = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    postReply(
      {
        body: values.message,
      },
      message.id,
      token,
      appUser.id
    )
      .then((res: any) => {
        if (message.privacyStatus === 0) {
          onPrivacyToggled(1, message.id);
        }
        setReplies([...replies, res]);
        formikHelpers.resetForm();
      })
      .catch((err: ApiResponse) => {
        onError(err);
      });
  };

  const handleReportSubmit = (
    values: ReportFormValues,
    formikHelpers: FormikHelpers<ReportFormValues>
  ) => {
    reportMessage(
      {
        reportOption: values.selectedOption,
        username: appUser.username,
      },
      message.id,
      token,
      appUser.id
    )
      .then((res: any) => {
        setOpenReportDialog(false);
        onMessageReported!(message.id);
        handleClose();
      })
      .catch((err: ApiResponse) => {
        onError(err);
      });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          padding: "0px",
        }}
      >
        <DialogTitle id="dialog-message-title">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={8}>
              <Typography>Anonymous user said...</Typography>
            </Grid>
            <Grid
              container
              item
              xs={4}
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton aria-label="menu" onClick={handleMenuClick}>
                <MoreVertOutlinedIcon />
              </IconButton>
              <Menu
                id="message-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "menu",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOpenReportDialog(true);
                  }}
                >
                  <ListItemIcon>
                    <ReportOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Report Message</ListItemText>
                </MenuItem>
              </Menu>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-message-body" marginBottom={2}>
            {
              <Typography
                color="textPrimary"
                variant="inherit"
                style={{
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                }}
              >
                {message?.body}
              </Typography>
            }
          </DialogContentText>
          <Typography align="center" variant="caption" display="block">
            hushup.app/{appUser.username}
          </Typography>
          <Divider variant="fullWidth" />
          <DialogContentText id="dialog-message-timestamp" marginTop={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography>
                {moment(+message?.timestamp).format("Do MMMM 'YY")}
              </Typography>
              <Grid item>
                <Button
                  id="privacy-toggle-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={privacyToggleOpen ? "true" : undefined}
                  sx={{ textTransform: "none" }}
                  onClick={handlePrivacyToggleClick}
                >
                  {message?.privacyStatus === 1 ? (
                    <Grid container justifyContent="center" alignItems="center">
                      <PublicOutlinedIcon />
                      <Typography>Public</Typography>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="center" alignItems="center">
                      <LockOutlinedIcon />
                      <Typography>Only Me</Typography>
                    </Grid>
                  )}
                </Button>
                <Menu
                  id="privacy-menu"
                  anchorEl={anchorEl}
                  open={privacyToggleOpen}
                  onClose={handlePrivacyToggleClose}
                  MenuListProps={{
                    "aria-labelledby": "privacy-toggle-button",
                  }}
                >
                  {(!message?.privacyStatus ||
                    message?.privacyStatus === 0) && (
                    <MenuItem
                      onClick={() => {
                        handlePrivacyToggled(1);
                      }}
                    >
                      <ListItemIcon>
                        <PublicOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Public</ListItemText>
                    </MenuItem>
                  )}
                  {message?.privacyStatus === 1 && (
                    <MenuItem
                      onClick={() => {
                        handlePrivacyToggled(0);
                      }}
                    >
                      <ListItemIcon>
                        <LockOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Only Me</ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              </Grid>
            </Grid>
          </DialogContentText>
          <CssBaseline />
          <MessageListView
            mode={1}
            messages={replies}
            onSelected={handleReplySelected}
          />
        </DialogContent>
        <DialogActions>
          <ReplyForm onSubmit={handleSubmitReply} />
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReportDialog}
        onClose={() => {
          setOpenReportDialog(false);
        }}
        aria-labelledby="report-dialog-title"
        aria-describedby="report-dialog-description"
      >
        <ReportForm onSubmit={handleReportSubmit} />
      </Dialog>
    </>
  );
};

export default MessageView;
