import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import firebase from "firebase/compat/app";

export const AuthContext = React.createContext<any>("");

export const useAuth = () => {
  return useContext(AuthContext);
};

interface Props {
  children: any;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User>();
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  const signup = (email: string, password: string) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  const updateEmail = (email: string) => {
    return currentUser!.updateEmail(email);
  };

  const updatePassword = (password: string) => {
    return currentUser!.updatePassword(password);
  };

  const updateUser = async (user: firebase.User) => {
    setCurrentUser(user!);
    if (user!) await user?.getIdToken(true).then((val) => setToken(val));
    else setToken(undefined);
  };

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      await updateUser(user!);
      setLoading(false);
    });
  }, []);

  const value = {
    currentUser,
    token,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    updateUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
