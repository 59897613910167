import React from "react";
import { Alert, Box, Grid } from "@mui/material";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import FormTextField from "./FormTextField";
import { UserPublic } from "../codelibrary/Models/UserPublic";
import { NavigateNextOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

interface FormValues {
  username: string;
  fullName: string;
  shortBio: string;
}

interface Props {
  mode?: 0 | 1; // 0 - Create Profile | 1 - Update Profile
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
  alert?: { severity: "error" | "success"; value?: string };
  initialValues: any;
}

const ProfileForm: React.FC<Props> = ({
  mode,
  initialValues,
  onSubmit,
  alert,
  ...props
}) => {
  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{ backgroundColor: "#fff" }}
          >
            <Grid
              container
              item
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              xs={10}
            >
              {mode === 0 && (
                <Grid item xs={12} marginTop={3}>
                  <Field
                    name="username"
                    label="Username"
                    size="small"
                    inputProps={{ maxLength: 15 }}
                    component={FormTextField}
                  />
                </Grid>
              )}
              <Grid item xs={12} marginTop={3}>
                <Field
                  name="fullName"
                  label="Full Name"
                  size="small"
                  component={FormTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Field
                  name="shortBio"
                  label="Bio"
                  size="large"
                  component={FormTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                marginTop={3}
              >
                {alert !== undefined && (
                  <Alert severity={alert.severity}>{alert.value}</Alert>
                )}
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color={"primary"}
                  loading={isSubmitting}
                  endIcon={<NavigateNextOutlined />}
                  loadingPosition="end"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
