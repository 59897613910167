import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FACEBOOK_PAGE,
  INSTA_PAGE,
  TWITTER_PAGE,
} from "../codelibrary/utility/constants";
import Footer from "../footer/Footer";

interface Props {}

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 180,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  full_vh: {
    display: "flex",
    alignItems: "center",
    flexGrow: "inherit",
  },
  infoHeader: {
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: theme.palette.secondary.main,
    zIndex: -1,
    "& h3": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  infoContent: {
    marginBottom: "1em",
  },
  footer: {
    color: theme.palette.text.primary,
    "& a": {
      color: "#606060",
    },
  },
  copyRight: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  bodyMargin: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
}));

const Contact: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      className={classes.bodyMargin}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.infoHeader}
      >
        <Typography variant="h3" align="center" className={classes.infoHeader}>
          Contact
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          xl={4}
          lg={6}
          md={8}
          xs={10}
          sm={8}
          direction="column"
          alignItems="center"
          paddingTop={1}
        >
          <Card>
            <CardContent>
              <Grid
                container
                justifyContent="center"
                className={classes.infoContent}
              >
                <Grid item xs={12} direction="column" alignItems="center">
                  <Grid container>
                    <Grid item>
                      <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                        Stay connected with HushUP
                      </Typography>
                    </Grid>
                    <Divider style={{ width: "100%" }} />
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="space-between"
                      alignItems="center"
                      paddingTop={2}
                    >
                      <Grid item xs={12} justifyContent={"center"}>
                        <Typography>
                          {`Like us on `}
                          <Link
                            color={"primary"}
                            href={FACEBOOK_PAGE}
                            target="_blank"
                          >
                            Facebook
                          </Link>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item xs={12} justifyContent={"center"}>
                        <Typography>
                          {`Connect with us on `}
                          <Link
                            color={"primary"}
                            href={INSTA_PAGE}
                            target="_blank"
                          >
                            Instagram
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} justifyContent={"center"}>
                        <Typography>
                          {`Follow on `}
                          <Link
                            color={"primary"}
                            href={TWITTER_PAGE}
                            target="_blank"
                          >
                            Twitter
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer quickLinks={true} />
    </Grid>
  );
};

export default Contact;
