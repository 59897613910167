import React from "react";
import { useAuth } from "./contexts/AuthContext";
import { Navigate, RouteProps } from "react-router-dom";

interface Props {
  path?: RouteProps["path"];
  component: React.ReactElement;
}

const PublicRoutes: React.FC<Props> = ({ path, component, ...props }) => {
  const { token } = useAuth();

  return !token ? component : <Navigate to="/me" />;
};

export default PublicRoutes;
